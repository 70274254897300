import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import AccordionGroup from "../components/accordionGroup/accordionGroup"
import Card from "../components/card/card"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../TC-Plataforma.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
const footerDesc = "<p>EVVA Plataforma S.A.P.I. de C.V. (EVVA) se dedica exclusivamente al desarrollo de la plataforma tecnológica (La Plataforma) a través de la cual usted puede adquirir y administrar servicios financieros empresariales y otorgando el uso de las misma a aquellas entidades financieras con las que se establezca relaciones comerciales o corporativas en términos de la legislación y normatividad aplicable. Por lo anterior, EVVA es únicamente el intermediario tecnológico entre el usuario de La Plataforma y dichas instituciones financieras otorgando derecho al uso de la misma, en el entendido que (i) EVVA no ofrece servicios financieros, (ii) EVVA no presta actividades financieras o actividades que se encuentren reguladas o supervisadas por autoridad alguna. (iii) Las entidades financieras con las que se establezca una relación comercial son entidades autorizadas por el Gobierno Federal y, por tanto, las únicas responsables de las operaciones financieras que celebre con sus clientes, aun cuando ello suceda a través de la Plataforma.</p>";
const contentMaxWidth = "820px";

const highYieldTab = <Card
    title="Cuenta empresarial de alto rendimiento"
    description="<p>Ya sea que seas una plataforma e-commerce, marketplace, o plataforma contable conecta una cuenta de alto rendimiento y automatiza tus operaciones diarias.</p>"
    descriptionColor="#363636"
    image="high-yield.png"
    imageWidth="250px"
    imageHeight="280px"
    marginTopContent="60px"
    //link="#"
    linkLabel="Conocer más"
    horizontal
/>;

const b2bPayments = <Card
    title="Pagos empresariales"
    description="<p>Ideal para ERPs y plataformas de contabilidad, dile adiós al papeleo, paga y recibe pagos de facturas de forma rápida, sencilla.</p>"
    descriptionColor="#363636"
    image="b2b-payments.png"
    imageWidth="250px"
    imageHeight="280px"
    marginTopContent="60px"
    //link="#"
    linkLabel="Conocer más"
    horizontal
/>;

const digitalAccountTab = <Card
    title="Administración de propiedades"
    description="<p>¿Eres una empresa de gestión inmobiliaria? Integra nuestra cuenta digital para que propietarios e inquilinos se mantengan organizados, ahorren tiempo y logren ingresos pasivos.</p>"
    descriptionColor="#363636"
    image="digital-account.png"
    imageWidth="250px"
    imageHeight="240px"
    marginTopContent="30px"
    //link="#"
    linkLabel="Conocer más"
    horizontal
/>;

const financialWellnessTab = <Card
    title="Bienestar financiero empresarial"
    description="<p>¿Eres una empresa de Nómina o de RRHH? Con nuestro fondo de ahorro empresarial de alto rendimiento, dile adiós al estrés financiero de tus colaboradores.</p>"
    descriptionColor="#363636"
    image="financial-wellness.png"
    imageWidth="250px"
    imageHeight="280px"
    marginTopContent="60px"
    //link="#"
    linkLabel="Conocer más"
    horizontal
/>;

const Plataforma = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const [itemSelected, setItemSelected] = useState(0);
    const items = [
        { id: 0, title: "E-commerce", content: highYieldTab },
        { id: 1, title: "Contabilidad", content: b2bPayments },
        { id: 2, title: "Proptech", content: digitalAccountTab },
        { id: 3, title: "RRHH", content: financialWellnessTab }
    ];

    const launchFormHandler = () => {
        window.open("https://evvafinanzas.typeform.com/to/VFsgS0", "blank");
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} title="EVVA, el motor financiero detrás de tu Empresa." description="Startup, PyME, eCommerce, Marketplace, desarrolla y lanza experiencias financieras digitales que crean valor para tu empresa y tus clientes"/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
            }
            <MainSection 
                title="El motor financiero detrás de tu Empresa"
                description="<h3>Desarrolla experiencias financieras digitales que crean valor para tu empresa y tus clientes.</h3>"
                textColor="primary"
                position="center"
                image="hero-platform.png"
                actionLabel="Comenzar"
                action={launchFormHandler} 
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUpPlataforma"
            >
                <AppBar pathname={appState.path}/>
            </MainSection>
            <PageSection 
                title="Creando experiencias digitales de la siguiente generación hoy"
                description="Sorprende a tus clientes con nuevas experiencias integrando tecnología financiera y creando valor para tu empresa"
                maxColumns="2"
                maxWidth={contentMaxWidth}
            >
                <Card 
                    subtitle="Crece tu empresa"
                    image="core-business.png"
                    imageWidth="378px"
                    imageHeight="274px"
                    description="<p>Impulsa el valor de vida de tus clientes a través de una nueva nueva oferta de productos financieros.</p>"
                    imageLeft
                />
                <Card 
                    subtitle="Deleita a tus clientes"
                    image="testimonial.png"
                    imageWidth="378px"
                    imageHeight="274px"
                    description="<p>Incrementa el compromiso, la retención, la lealtad y la satisfacción de tus clientes</p>"
                    imageLeft
                />
                <Card 
                    subtitle="Nuevas experiencias digitales"
                    image="digital-experience.png"
                    imageWidth="378px"
                    imageHeight="274px"
                    description="<p>Lanza nuevas experiencias financieras en días, no en meses</p>"
                    imageLeft
                />
                <Card 
                    subtitle="Tu empresa, tu marca"
                    image="business-brand.png"
                    imageWidth="378px"
                    imageHeight="274px"
                    description="<p>Personaliza servicios financieros que se adapten a tu marca y tus clientes</p>"
                    imageLeft
                />
            </PageSection>
            <PageSection title="Algunos de nuestras experiencias financieras para tus clientes">
                <AccordionGroup items={items} itemSelected={itemSelected} setItemSelected={setItemSelected}/>
            </PageSection>
            <PageSection
                title="¿Cómo lo hacemos?"
                description="EVVA proporciona componentes financieros modulares y listos para usar, agregando valor a tu empresa y clientes. Colaboramos con las mejores instituciones financieras del mercado para que esto suceda."
                >
                    <div style={{textAlign: "center", margin: "0 auto"}}>
                        <img src="../../build-components.png" alt="See the impact you made" width="100%"/>
                    </div>
            </PageSection>
            <PageSection 
                maxColumns="4"
                title="Conoce la plataforma plug & play"
                description="En EVVA colaboramos con las mejores instituciones financieras del mercado para ofrecer diversos productos financieros directamente a través de tu plataforma. Nos hemos encargado de toda la complejidad técnica y negociaciones complejas con la finalidad de que sea fácil de comenzar y escalar."
            >
                <Card 
                    subtitle="Cuenta digital"
                    description="<p>Conecta una cuenta digital de alto rendimiento en tu platforma</p>"
                    image="digital-account-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="KYC Identificación"
                    description="<p>Verifica la identidad de empresas a individuos </p>"
                    image="kyc-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="Escrow"
                    description="<p>Evite el fraude y asegura tus transacciones en Escrow.</p>"
                    image="escrow-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="Pagos digitales"
                    description="<p>No más pagos atrasados, Paga Proveedores, Facturas y Automatiza tus operaciones.</p>"
                    image="card-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="SAT"
                    description="<p>Conecta tu cuenta SAT y acceda a toda tu información fiscal.</p>"
                    image="tax-data-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="Firma digital"
                    description="<p>Firma tus contratos de forma remota y segura utilizando tu firma digital e.Firma</p>"
                    image="signature-icon-v2.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="Transferencias Internacionales"
                    description="<p>Nunca fue tan fácil pagar a tus proveedores internacionales y con el mejor FX.</p>"
                    image="international-trading-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="Multibanco"
                    description="<p>Conecta todas sus cuentas bancarias para obtener una visibilidad total de tus finanzas</p>"
                    image="accounts-overview-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
            </PageSection>
            <SecondarySection
                title="¿Tienes algo más en mente?"
                shortDescription="No dudes en contactarnos y con gusto te ayudaremos a explorar cómo con EVVA puedes crear nuevas experiencias financieras personalizadas para tu empresa y tus clientes."
                position="center"
                image="evva-lego-bricks.png"
                actionLabel="Contactar"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUpPlataforma"
            />
        </Layout>
    )
};

export default Plataforma
